// Тип девайса
export enum Device {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

// Тип операционной системы
export enum DeviceOS {
  Android = 'android',
  Blackberry = 'blackberry',
  IEMobile = 'ie_mobile',
  IOS = 'ios',
  OperaMini = 'opera_mini',
  WindowsPhone = 'windows_phone',
}
