import { DeviceOS } from '../../constants';

interface DeviceInfo {
  isMobileDevice: boolean;
  deviceOperationSystem?: DeviceOS;
}

export const getDeviceInfo = (): DeviceInfo => {
  const userAgent = window.navigator.userAgent;

  if (!userAgent) {
    return { isMobileDevice: false };
  }

  const osMap = {
    [DeviceOS.Android]: /Android/i,
    [DeviceOS.Blackberry]: /BlackBerry/i,
    [DeviceOS.IEMobile]: /IEMobile/i,
    [DeviceOS.IOS]: /(iPad|iPhone|iPod).+OS \d+/i,
    [DeviceOS.OperaMini]: /Opera Mini/i,
    [DeviceOS.WindowsPhone]: /Windows Phone/i,
  };

  for (const [os, regex] of Object.entries(osMap)) {
    if (regex.test(userAgent)) {
      return {
        isMobileDevice: true,
        deviceOperationSystem: os as DeviceOS,
      };
    }
  }

  return { isMobileDevice: false };
};
